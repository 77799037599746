import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/auth';
import AuthService from '../../services/auth';
import { Media, Button } from './styles';
import { Exit } from 'styled-icons/icomoon';

const Logout = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(actions.logout());
    AuthService.clearSession();
    AuthService.logout();
  };

  return (
    <Button onClick={logout}>
      <Media>
        <Exit />
      </Media>
    </Button>
  );
};

export default Logout;
