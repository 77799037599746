import React from 'react';
import { Scope } from '@unform/core';

import { useCadastro } from '../../../contexts/Cadastro';

import ViaCepService from '../../../services/viaCep';
import { estados } from '../../../config/enums';
import Input from '../../../components/UI/Input';
import Select from '../../../components/UI/Select';
import InputCidade from './InputCidade';
import InputWithMask from '../../../components/UI/InputWithMask';
import { Fieldset } from '../styles';

const Endereco = () => {
  const { setUf, setCep, cepNotFound, setCepNotFound } = useCadastro();

  const handleCep = async cep => {
    var cepNumber = cep.replace(/[^0-9]+/g, '');

    if (cepNumber.length === 8) {
      const data = await ViaCepService.getEndereco(cepNumber);

      if (!data) {
        setCepNotFound(true);
        return;
      }
      setCep(data);
      setCepNotFound(false);
    }
  };

  const handleUf = uf => {
    setUf(uf);
  };

  return (
    <>
      <Fieldset inline>
        <InputWithMask
          name="cep"
          label="CEP"
          mask="99999-999"
          eventOnBlur={handleCep}
        />
        <Scope path="cidade.estado">
          <Select
            name="uf"
            label="Estado"
            disabled={!cepNotFound}
            data={estados}
            eventOnChange={handleUf}
          />
        </Scope>
      </Fieldset>
      <Fieldset>
        <Scope path="cidade">
          <InputCidade name="codigoIBGE" label="Cidade" />
        </Scope>
      </Fieldset>
      <Fieldset inline>
        <Input name="bairro" label="Bairro" disabled={!cepNotFound} />
        <Input name="logradouro" label="Logradouro" disabled={!cepNotFound} />
      </Fieldset>
      <Fieldset inline>
        <Input name="numero" label="Número" />
        <Input name="complemento" label="Complemento" />
      </Fieldset>
    </>
  );
};

export default Endereco;
