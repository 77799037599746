import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MatriculaAlunoService from '../../services/matriculaAluno';
import CardInfoCurso from './CardInfoCurso';
import Title from '../../components/Title';
import Pagination from '../../components/Pagination';
import { Container } from './styles';
import { actions } from '../../store/modules/auth';

const Cursos = () => {
  const dispatch = useDispatch();
  const { cpf } = useSelector(state => state.auth.user);
  const [matriculas, setMatriculas] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function getMatriculas() {
      dispatch(actions.setSpinner(true));
      const response = await MatriculaAlunoService.getDadosTurma(cpf);
      if (response.status === 200) {
        setMatriculas(response.data.reverse());
      }
      dispatch(actions.setSpinner(false));
    }
    getMatriculas();
  }, []);

  const listaMatriculas = useMemo(() => {
    let listaPaginada = [];
    let i = 1;
    for (const mat of matriculas) {
      if (
        listaPaginada[i] &&
        listaPaginada[i].length >= 6 /** TODO: Criar uma constate */
      ) {
        i++;
      }
      listaPaginada[i] = listaPaginada[i] || [];
      listaPaginada[i].push(mat);
    }
    return listaPaginada;
  }, [matriculas]);

  return (
    <Container>
      <Title descricao="Meus Cursos"/>
      {listaMatriculas && listaMatriculas.length > 0 && (
        <>
          {listaMatriculas[page].map(m => (
            <CardInfoCurso key={m.matricula} matricula={m} />
          ))}
        </>
      )}
      {listaMatriculas && listaMatriculas.length > 1 && (
        <Pagination
          page={page}
          setPage={setPage}
          count={listaMatriculas.length - 1}
        />
      )}
    </Container>
  );
};

export default Cursos;
