import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Container } from './styles';

const CardInfoParcela = ({ parcela }) => {
  const vencimento = format(new Date(parcela.vencimento), 'dd/MM/yyyy');

  return (
    <Container>
      <div>
        <strong>Nº Parcela: </strong>
        <span>{parcela.numeroParcela}</span>
      </div>
      <div>
        <strong>Vencimento: </strong>
        <span>{vencimento}</span>
      </div>

      <div>
        <strong>Valor: </strong>
        <span>
          R${' '}
          {parcela.valor.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
      <div>
        <strong>Situacao: </strong>
        <span>{parcela.situacao}</span>
      </div>

      {parcela.imprimir && (
        <Link
          to={{
            pathname: '/boleto',
            search: `?parcela=${parcela.idParcela}`,
          }}
          target="_blank"
        >
          Imprimir
        </Link>
      )}
    </Container>
  );
};

export default CardInfoParcela;
