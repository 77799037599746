import React, { useRef, useState, useEffect, memo } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';

const InputWithMask = ({
  name,
  label,
  mask,
  disabled = false,
  eventOnBlur = null,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [inputValue, setInputValue] = useState(defaultValue || '');

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        getValue() {
          return inputRef.current.value;
        },
        setValue(_, newValue) {
          setInputValue(newValue);
        },
      });
    }
  }, [fieldName, registerField]);

  const handleChange = value => {
    setInputValue(value);
  };

  const handleOnBlur = value => {
    if (typeof eventOnBlur === 'function') {
      eventOnBlur(value);
    }
  };

  return (
    <InputMask
      mask={mask}
      value={inputValue}
      onChange={({ target: { value } }) => handleChange(value)}
      onBlur={({ target: { value } }) => handleOnBlur(value)}
      disabled={disabled}
    >
      <TextField
        id={name}
        name={name}
        size="small"
        label={label}
        inputProps={{ ref: inputRef }}
        error={Boolean(error)}
        helperText={error || ''}
        {...rest}
      />
    </InputMask>
  );
};

export default memo(InputWithMask);
