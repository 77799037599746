import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
import CardInfoParcela from './CardInfoParcela';
import Title from '../../components/Title';
import ParcelaService from './../../services/parcela';
import { actions } from '../../store/modules/auth';

const Financeiro = () => {
  const dispatch = useDispatch();
  const { matricula } = useSelector(state => state.auth);
  const [parcelas, setParcelas] = useState([]);

  useEffect(() => {
    async function getParcelas() {
      if (matricula) {
        dispatch(actions.setSpinner(true));
        const data = await ParcelaService.getDadosParcela(matricula.matricula);
        if (data) {
          setParcelas(data.reverse());
        } else {
          setParcelas({});
        }
        dispatch(actions.setSpinner(false));
      }
    }
    getParcelas();
  }, [matricula]);

  return (
    <Container>
      <Title descricao="Financeiro"/>
      <p>
        Matricula selecionada:{' '}
        {matricula ? matricula.matricula : 'Nenhuma.'}
      </p>

      {parcelas && parcelas.length > 0 && (
        <>
          {parcelas.map(p => (
            <CardInfoParcela key={p.idParcela} parcela={p} />
          ))}
        </>
      )}
    </Container>
  );
};

export default Financeiro;
