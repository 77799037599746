export const onlineServices = [
  {
    key: 'ava',
    header: 'Acessar meu AVA',
    description: 'Acesse o seu ambiente virtual de aprendizagem',
    target: '_blank',
    active: true,
    cardColor: '#E1F0FF',
    icon: {
      name: 'laptop',
      color: '#FFFFFF',
      background: '#91A8D1',
      width: '18px',
      height: '16px',
    },
  },
  {
    key: 'cadastro',
    header: 'Dados Cadastrais',
    description: 'Mantenha seus dados sempre atualizados',
    target: '_self',
    active: true,
    cardColor: '#FFF4DE',
    icon: {
      name: 'document',
      color: '#FFFFFF',
      background: '#FFA802',
      width: '18px',
      height: '22px',
    },
  },
  {
    key: 'academico',
    header: 'Acadêmico',
    description: 'Faltas, espelho do histórico escolar e mais',
    target: '_self',
    active: true,
    cardColor: '#FFE2E5',
    icon: {
      name: 'capelo',
      color: '#FFFFFF',
      background: '#F64E60',
      width: '21px',
      height: '18px',
    },
  },
  {
    key: 'financeiro',
    header: 'Financeiro',
    description: 'Gerencie os seus pagamentos junto ao Senac',
    target: '_self',
    active: true,
    cardColor: '#C8F7F5',
    icon: {
      name: 'money',
      color: '#FFFFFF',
      background: '#1BC5BD',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: 'cursos',
    header: 'Meus Cursos',
    description: 'Veja a sua trajetória de aprendizado no Senac',
    target: '_self',
    active: true,
    cardColor: '#D2F8E9',
    icon: {
      name: 'book',
      color: '#FFFFFF',
      background: '#7DD1B0',
      width: '20px',
      height: '20px',
    },
  },
  {
    key: 'bo',
    header: 'Banco de Oportunidades',
    description: 'Veja as oportunidads de trabalho disponíveis',
    target: '_blank',
    active: true,
    cardColor: '#EEE5FF',
    icon: {
      name: 'bag',
      color: '#FFFFFF',
      background: '#BC9DFE',
      width: '21px',
      height: '19px',
    },
  },
];
