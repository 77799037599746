import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

export const Container = styled(Dialog)`
  > div {
    > div {
      max-width: 700px;
      width: 100%;
      padding: 18px;
    }
  }
`;

export const ListItem = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  box-shadow: 4px 4px 10px rgba(120, 135, 182, 0.25);
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

export const Media = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #2db1e6;
  margin-right: 4px;

  > svg {
    width: 20px;
    height: 20px;
    color: #fff;
  }
`;

export const Content = styled.div`
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2px;
  width: 100%;
  margin-left: 10px;
  align-items: center;

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1px;
  }

  strong {
    font-size: 14px;
    font-weight: 700;
    color: #222222;
    line-height: 20px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #778a9e;
    line-height: 20px;
    margin-left: 6px;
  }
`;
