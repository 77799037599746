import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  background: none;
  justify-content: center;
  margin: 16px 0 0;

  button {
    width: 200px;
    height: 50px;
    border-radius: 5px;
    background: ${props =>
      props.background ? props.background : 'var(--senac-primary)'};
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const Button = ({ children, type = 'button', ...rest }) => {
  return (
    <Container>
      <button type={type} {...rest}>
        {children}
      </button>
    </Container>
  );
};

export default Button;
