import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import AlunoService from '../services/aluno';
import { actions } from '../store/modules/auth';

const CadastroContext = createContext();

const CadastroProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { cpf } = useSelector(state => state.auth.user);
  const [cadastro, setCadastro] = useState(null);
  const [cep, setCep] = useState(null);
  const [uf, setUf] = useState(null);
  const [cidade, setCidade] = useState({ codigoIBGE: '', descricao: '' });
  const [cepNotFound, setCepNotFound] = useState(false);

  const save = async data => {
    dispatch(actions.setSpinner(true));
    const response = await AlunoService.updateDadosAluno(data);
    dispatch(actions.setSpinner(false));
    if (response.status === 200) {
      toast.success('Informações atualizadas com sucesso.');
      return;
    }

    toast.warning('Não foi possível atualizar suas informações.');
  };

  useEffect(() => {
    async function loadDados() {
      dispatch(actions.setSpinner(true));
      const data = await AlunoService.getDadosAluno(cpf);
      setUf(data.endereco.cidade.estado.uf);
      setCidade({
        codigoIBGE: data.endereco.cidade.codigoIBGE,
        descricao: data.endereco.cidade.descricao,
      });
      setCadastro(data);
      dispatch(actions.setSpinner(false));
    }
    loadDados();
  }, []);

  return (
    <CadastroContext.Provider
      value={{
        cadastro,
        cep,
        setCep,
        uf,
        setUf,
        cidade,
        setCidade,
        setCadastro,
        cepNotFound,
        setCepNotFound,
        save,
      }}
    >
      {children}
    </CadastroContext.Provider>
  );
};

const useCadastro = () => {
  const context = useContext(CadastroContext);

  if (!context) {
    throw new Error('useCadastro deve ser usando em um CadastroProvider!');
  }

  return context;
};

export { CadastroProvider, useCadastro };
