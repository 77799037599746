import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import * as routerHelpers from '../../router/helpers';
import AuthService from '../../services/auth';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  Matricula: '[Matricula] Action',
  Spinner: '[Spinner] Action'
};

const initialAuthState = {
  user: {
    fullname: '',
    token: '',
  },
  matricula: undefined,
  authToken: undefined,
  spinner: false
};

export const reducer = persistReducer(
  {
    storage,
    key: 'portal-aluno',
    whitelist: ['user', 'authToken', 'matricula', 'spinner'],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.Matricula: {
        const { matricula } = action.payload;
        return { ...state, matricula };
      }

      case actionTypes.Spinner: {
        const { spinner } = action.payload;
        return { ...state, spinner };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({ type: actionTypes.UserRequested }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setMatricula: matricula => ({
    type: actionTypes.Matricula,
    payload: { matricula },
  }),
  setSpinner: spinner => ({
    type: actionTypes.Spinner,
    payload: { spinner }
  })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { cpf, fullname } = yield AuthService.getUserSession();

    yield put(actions.fulfillUser({ cpf, fullname }));
  });
}
