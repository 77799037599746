import React, { useRef, useState, useEffect, memo } from 'react';
import { useField } from '@unform/core';
import { TextField, MenuItem } from '@material-ui/core';

const Select = ({
  name,
  label,
  data,
  dataContract = { value: 'value', label: 'label' },
  eventOnChange = null,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [inputValue, setInputValue] = useState(defaultValue || '');

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        getValue() {
          return inputRef.current.value;
        },
        setValue(_, newValue) {
          setInputValue(newValue);
        },
      });
    }
  }, [fieldName, registerField]);

  const handleChange = value => {
    setInputValue(value);

    if (typeof eventOnChange === 'function') {
      eventOnChange(value);
    }
  };

  return (
    <TextField
      id={name}
      name={name}
      select
      size="small"
      label={label}
      value={inputValue}
      onChange={({ target: { value } }) => handleChange(value)}
      inputProps={{ ref: inputRef }}
      error={Boolean(error)}
      helperText={error || ''}
      {...rest}
    >
      {data.map(option => (
        <MenuItem
          key={option[dataContract.value]}
          value={option[dataContract.value]}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default memo(Select);
