import React from 'react';
import { Container, Media, Content } from './styles';
import { icons } from '../../styles/Icons';

const CardService = ({ content }) => {
  const Icon = icons[content.icon.name];

  return (
    <Container to={content.key} color={content.cardColor} target={content.target}>
      <Media icon={content.icon}>
        <Icon />
      </Media>
      <Content>
        <header>{content.header}</header>
        <p>{content.description}</p>
      </Content>
    </Container>
  );
};

export default CardService;
