import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import * as routerHelpers from './helpers';
import Auth from '../pages/Auth';
import Interceptor from '../components/Interceptor';
import env from '../config/environments';
import { ToastContainer } from 'react-toastify';
import Home from '../pages/Home';

const Routes = withRouter(() => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);

  const { isAuthorized, userLastLocation } = useSelector(
    ({ auth, urls }) => ({
      isAuthorized:
        auth.user != null && auth.user.fullname != '' && auth.user.token != '',
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  return (
    <>
      <Interceptor />
      <ToastContainer autoClose={env.toast.timer} />
      <Switch>
        {!isAuthorized ? (
          <Auth />
        ) : (
          <Home userLastLocation={userLastLocation} />
        )}
      </Switch>
    </>
  );
});

export default Routes;
