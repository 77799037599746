import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;

  > header {
    width: 100%;
    margin: 10px 0;
    font-weight: 700;
    font-size: 18px;
    color: #222222;
    line-height: 22px;
  }

  p {
    margin: 10px 0;
    font-weight: 600;
  }

  @media (max-width: 1125px) {
    width: 100%;
    max-width: 762px;
    margin-right: unset;
  }
`;

export const ListItem = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 4px 4px 10px rgba(120, 135, 182, 0.25);
    background: #f0f3f6;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;

  div {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #778a9e;
    line-height: 20px;
    margin-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Media = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;

  > svg {
    width: 22px;
    height: 22px;
    color: #778a9e;
  }
`;
