import React, { useEffect } from 'react';
import { Main } from './styles';
import PulseLoader from "react-spinners/PulseLoader";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from 'react-redux';

const Spinner = props => {
  const {spinner} = useSelector(state => state.auth);

  useEffect(() => {
  }, [spinner])

  return (
    <Main>
      <LoadingOverlay
        active={spinner}
        spinner={<PulseLoader size={30} color={"#ff9800"} />}
        styles={{
          overlay: base => ({
            ...base,
            background: 'rgba(50, 50, 50, 0.5)'
          })
        }}
      >
        {props.children}
      </LoadingOverlay>
    </Main>
  )
}

export default Spinner;
