import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import store, { persistor } from './store';
const { PUBLIC_URL } = process.env;

ReactDOM.render(
  <React.StrictMode>
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />
  </React.StrictMode>,
  document.getElementById('root')
);
