import React from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../services/auth';

import { Button } from '../styles';

const AuthPage = () => {
  const history = useHistory();

  return (
    <>
      <Button
        className="primary"
        onClick={() => AuthService.redirectToLogin()}>
          Acessar Portal do Aluno
      </Button>

      <Button
        className="secundary"
        onClick={() => history.push('/password-request')}>
          Recuperar minha senha
      </Button>
    </>
  );
};

export default AuthPage;
