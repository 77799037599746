import React from 'react';
import {Media} from './styles';
import { icons } from '../../../styles/Icons';
import { segmentos } from '../../../config/segmentos';

const IconCurso = ({segmento}) => {

  const segmentoMatricula = segmentos.find(s => s.key === segmento);
  let Icon = icons['book'];
  let configIcon = {
    color: '#FFFFFF',
    background: '#28B2E7',
    width: '22px',
    height: '22px',
  };
  if (segmentoMatricula) {
    Icon = icons[segmentoMatricula.icon.name];
    configIcon = segmentoMatricula.icon;
  }

  return (
    <Media icon={configIcon}>
      <Icon />
    </Media>
  );
};

export default IconCurso;
