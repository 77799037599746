import React from 'react';
import { onlineServices } from '../../config/content-services';
import CardService from '../../components/CardService';
import { Container, Services } from './styles';

const Servicos = () => {
  return (
    <Container>
      <header>Serviços online</header>
      <Services>
        {onlineServices.map(contentService => (
          <CardService key={contentService.key} content={contentService} />
        ))}
      </Services>
    </Container>
  );
};

export default Servicos;
