import styled, { css } from 'styled-components';
import { Form as Unform } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > header {
    width: 100%;
    margin: 24px 0;
    font-weight: 700;
    font-size: 18px;
    color: #222222;
    line-height: 22px;
  }
`;

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  background: #fff;

  @media (min-width: 700px) {
    border-left: 1px solid var(--senac-secundary);
    padding-left: 22px;
  }
`;

export const Fieldset = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  > div {
    margin-bottom: 16px;
  }

`;

export const Section = styled.strong`
  margin: 20px 0;
  font-size: 16px;
`;
