import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lato', sans-serif;
`;

export const Content = styled.div`
  margin-top: 40px;
  width: 100%;
  max-width: 1125px;
  display: flex;

  @media (max-width: 1125px) {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 20px;
  }
`;

export const Main = styled.div`
  width: 100%;
  max-width: 762px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 44px;
  border-radius: 6px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    padding: 24px;
  }

  @media (max-width: 390px) {
    padding: 14px;
  }
`;
