import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    width: 100%;
    height: 100vh;
    background: #EFF2F6;

    font-family: 'Lato', sans-serif;
  }

  button, input {
    border: 0;
    font-family: 'Lato', sans-serif;
  }

  :root {
    --senac-primary: #004a8d;
    --senac-secundary: #fba033;
    --outline: #dbe0e6;
  }
`;
