import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Servicos from '../Servicos';
import Financeiro from '../Financeiro';
import Cursos from '../Cursos';
import Cadastro from '../Cadastro';
import Boleto from '../Boleto';
import Ava from '../Ava';
import Bo from '../Bo';
import Academico from '../Academico';
import Errors from '../Errors';
import Layout from '../_layout';

const HomePage = () => {
  return (
    <Switch>
      <RouteWrapper exact path="/" component={Servicos} />
      <RouteWrapper path="/financeiro" component={Financeiro} />
      <RouteWrapper path="/cursos" component={Cursos} />
      <RouteWrapper path="/cadastro" component={Cadastro} />
      <RouteWrapper path="/boleto" component={Boleto} fullpage />
      <RouteWrapper path="/ava" component={Ava} />
      <RouteWrapper path="/bo" component={Bo} />
      <RouteWrapper path="/academico" component={Academico} />
      <RouteWrapper path="/error" component={Errors} fullpage />
    </Switch>
  );
};

export default HomePage;

const RouteWrapper = ({ component: Component, fullpage = false, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          {!fullpage ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Component {...props} />
          )}
        </>
      )}
    />
  );
};
