import React from 'react';
import env from '../../config/environments';
import api from '../../services/api';
import AuthService from '../../services/auth';
import { toast } from "react-toastify";

const Interceptor = props => {

  const notAuthUrl = url => {
    let notAuth = false;
    for (let endpoint of env.auth.routerNotAuth) {
      if (url.toLowerCase().startsWith(endpoint)) {
        notAuth = true;
      }
    }

    return notAuth;
  };

  const toastError = (data, status) => {
    if (status === 400) {
      if (data.content.length > 0) {
        data.content.forEach(e => toast.error(e));
        return;
      }

      toast.error(
        'Ocorreu um erro não esperado ao tentar processar sua requisição. Por favor, atualize a página e tente novamente.'
      );
      return;
    }

    toast.error(
      'Ocorreu um erro não esperado ao tentar processar sua requisição. Por favor, atualize a página e tente novamente.'
    );
    return;
  };

  api.interceptors.request.use(async function (config) {
    if (!notAuthUrl(config.url)) {
      let auth = AuthService.getSession();
      if (auth && auth.accessToken) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`;
      }
    }

    return config;
  });

  api.interceptors.response.use(
    async function (response) {
      return response;
    },
    async function (error) {
      if (error.message === 'Network Error' && !error.response) {
        toastError();
        return;
      }

      const {
        config,
        response: { status, data },
      } = error;

      const originalRequest = config;

      if (status === 401 && !notAuthUrl(config.url)) {
        let response = await AuthService.refreshToken();

        if (response.status === 200) {
          const retryOriginalRequest = new Promise(resolve => {
            resolve(api(originalRequest));
          });

          return retryOriginalRequest;
        } else {
          AuthService.redirectToLogin();
        }
      }

      if (
        (status === 400 || status > 401) &&
        (config.toast === undefined || config.toast === true)
      ) {
        return toastError(data, status);
      }

      /*if (status === 401) {
        AuthService.clearSession();
        AuthService.logout();
      }*/

      return Promise.reject(error);
    }
  );

  return <></>;
};

export default Interceptor;
