import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import BoletoService from './../../services/boleto';
import { useSelector } from 'react-redux';

const Boleto = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const parcela = params.get('parcela');
  const [boleto, setBoleto] = useState('');
  const matriculaState = useSelector(state => state.auth.matricula);

  const GetBoletoSei = async parcela => {
    const response = await BoletoService.getBoletoSei(
      matriculaState.matricula,
      parcela
    );
    setBoleto(response);

    let IEwindow = window;
    IEwindow.document.body.innerHTML = response;
    setTimeout(() => {
      IEwindow.document.write(response);
    }, 2000);
    IEwindow.document.close();
  };

  useEffect(() => {
    GetBoletoSei(parcela);
  }, [parcela]);

  return (
    <Card>
      {boleto && <div dangerouslySetInnerHTML={{ __html: boleto }} />}
    </Card>
  );
};

export default Boleto;
