import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  padding: 22px;
  border-radius: 6px;
  background: ${props => props.color};
  display: flex;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
`;

export const Media = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background: ${props => props.icon.background};
  border-radius: 21px;
  margin-top: 15px;

  > svg {
    width: ${props => props.icon.width};
    height: ${props => props.icon.height};
    color: #fff;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: calc(100% - 58px);

  > header {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #222222;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #778a9e;
    margin-top: 8px;
  }
`;
