import React, { createContext, useContext, useState, useEffect } from 'react';
import AuthService from '../services/auth';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [matricula, setMatricula] = useState(null);
  const [user, setUser] = useState({
    nome: '',
    cpf: '',
  });

  useEffect(() => {
    if (AuthService.isAuth()) {
      const { fullname } = AuthService.getUserSession();
      setUser({
        nome: fullname,
        cpf: '',
      });
      setIsAuthorized(true);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthorized,
        setIsAuthorized,
        user,
        setUser,
        setMatricula,
        matricula
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth deve ser usando em um AuthProvider!');
  }

  return context;
};

export { AuthProvider, useAuth };
