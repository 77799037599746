import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { Scope } from '@unform/core';
import { mapper } from './mapper';
import { validate } from './validation';
import Input from '../../../components/UI/Input';
import InputWithMask from '../../../components/UI/InputWithMask';
import Select from '../../../components/UI/Select';
import Button from '../../../components/UI/Button';
import { sexo, tipoTelefone, escolaridade } from '../../../config/enums';
import { Form as Container, Fieldset, Section } from '../styles';
import { useCadastro } from '../../../contexts/Cadastro';

import Endereco from '../Endereco';

const Form = () => {
  const { cep, setCidade, setUf, cadastro, cepNotFound, save } = useCadastro();
  const formRef = useRef(null);

  useEffect(() => {
    if (cadastro) {
      formRef.current.setData({
        ...cadastro,
        dataNascimento: format(new Date(cadastro.dataNascimento), 'dd/MM/yyyy'),
      });
    }
  }, [cadastro]);

  useEffect(() => {
    if (cep) {
      handleEndereco(cep);
    }
  }, [cep]);

  useEffect(() => {
    if (cepNotFound) {
      handleEndereco({
        logradouro: '',
        localidade: '',
        bairro: '',
        uf: '',
        ibge: '',
      });
    }
  }, [cepNotFound]);

  const handleEndereco = ({
    logradouro = '',
    localidade = '',
    bairro = '',
    uf = '',
    ibge = '',
  }) => {
    formRef.current.setFieldValue('endereco.logradouro', logradouro);
    formRef.current.setFieldValue('endereco.bairro', bairro);
    formRef.current.setFieldValue('endereco.cidade.estado.uf', uf);
    setUf(uf);
    setCidade({ codigoIBGE: ibge, descricao: localidade.toUpperCase() });
  };

  const handleSubmit = async data => {
    const error = await validate(data);

    if (error) {
      formRef.current.setErrors(error);
      return;
    }
    formRef.current.setErrors(false);

    save(mapper(data));
  };

  return (
    <Container ref={formRef} onSubmit={handleSubmit}>
      <Fieldset inline>
        <InputWithMask name="cpf" label="CPF" mask="999.999.999-99" disabled />
        <Input name="email" label="E-mail" />
      </Fieldset>
      <Fieldset inline>
        <Input name="nome" label="Nome" />
        <Input name="nomeMae" label="Nome da Mãe" disabled />
      </Fieldset>
      <Fieldset inline>
        <Input name="dataNascimento" label="Data Nascimento" disabled />
        <Select name="sexo" label="Sexo" data={sexo} />
      </Fieldset>
      <Fieldset inline>
        <Select name="escolaridade" label="Escolaridade" data={escolaridade} />
      </Fieldset>

      <Section>Telefone</Section>
      <Fieldset inline>
        <Scope path="telefones[0]">
          <Select name="tipo" label="Tipo" data={tipoTelefone} />
          <InputWithMask name="numero" label="Numero" mask="(99)99999-9999" />
        </Scope>
      </Fieldset>

      <Section>Endereço</Section>
      <Scope path="endereco">
        <Endereco />
      </Scope>

      <Button type="submit">Gravar</Button>
    </Container>
  );
};

export default Form;
