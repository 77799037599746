import api from './api';

export default class AlunoService {
  static async getDadosAluno(cpf) {
    const response = await api.get(`v1/aluno/${cpf.replace(/\D/g, '')}`);
    return response.data;
  }

  static async updateDadosAluno(data) {
    return await api.put('v1/aluno', data);
  }
}
