import env from '../config/environments';
import api from './api';
import jwtDecode from 'jwt-decode';

class AuthService {
  redirectToLogin() {
    this.clearSession();
    window.location = `${env.auth.baseUrlAuth}${env.auth.endPointLogin}?client_id=${env.auth.clientId}&response_type=${env.auth.responseType}&resource=${env.auth.resource}&redirect_uri=${env.auth.redirectUri}`;
    return;
  }

  logout() {
    window.location = `${env.auth.baseUrlAuth}${env.auth.endPointLogout}?post_logout_redirect_uri=${env.auth.redirectUri}`;
    return;
  }

  async loginByAutorizationCode(code) {
    const response = await api.post('/v1/auth', { code });

    if (response && response.status === 200) {
      this.initSession(response.data);
    }

    return response;
  }

  async refreshToken() {
    const session = this.getSession();
    const response = await api.post('/v1/auth/refresh-token', {
      RefreshToken: session.refreshToken,
    });

    if (response.status === 200) {
      this.initSession(response.data);
    }

    return response;
  }

  initSession(user) {
    localStorage.setItem('userSession', JSON.stringify(user));
  }

  isAuth() {
    return this.getSession() !== null;
  }

  getSession() {
    const session = localStorage.getItem('userSession');

    if (session) {
      return JSON.parse(session);
    }

    return null;
  }

  clearSession() {
    localStorage.clear();
  }

  getUserSession() {
    if (this.isAuth()) {
      const session = this.getSession();
      let decode = jwtDecode(session.accessToken);
      return {
        roles: Array.isArray(decode.role) ? decode.role : [decode.role],
        cpf: decode.cpf,
        fullname: session.displayName,
      };
    }

    return {
      fullname: '',
    };
  }
}

export default new AuthService();
