import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions } from '../../../store/modules/auth';
import AuthService from '../../../services/auth';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const paramsBusca = new URLSearchParams(search);
  const code = paramsBusca.get('code');

  useEffect(() => {
    loginByAutorizationCode();
  }, []);

  const loginByAutorizationCode = async () => {
    if (code !== null) {
      dispatch(actions.setSpinner(true));

      const response = await AuthService.loginByAutorizationCode(code);

      dispatch(actions.setSpinner(false));

      if (response && response.status === 200) {
        dispatch(actions.login(response.data.accessToken));
        dispatch(actions.requestUser());
      }
    }

    history.push('/');
  };

  return (
    <></>
  );
};

export default Login;
