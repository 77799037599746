import React from 'react';
import { Container } from './styles';
import { CadastroProvider } from '../../contexts/Cadastro';
import Title from '../../components/Title';
import Form from './Form';

const Cadastro = () => {
  return (
    <CadastroProvider>
      <Container>
        <Title descricao="Dados Cadastrais"/>
        <Form />
      </Container>
    </CadastroProvider>
  );
};

export default Cadastro;
