import styled from 'styled-components';

export const Media = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 30px;

  > svg {
    width: 20px;
    height: 20px;
    color: #778a9e;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #ffffff;
  background: #eff2f6;
  width: 36px;
  height: 36px;
  margin-left: 12px;
  border-radius: 4px;
  border: 0;
  outline: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
