import React, { useRef, useEffect, useState, memo } from 'react';
import { useField } from '@unform/core';
import TextField from '@material-ui/core/TextField';

const Input = ({ label, name, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [shrink, setShrink] = useState(Boolean(defaultValue));

  const handleChange = newValue => {
    setInputValue(newValue);
    setShrink(Boolean(newValue));
  };

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        setValue(_, value) {
          handleChange(value);
        },
      });
    }
  }, [fieldName, registerField]);

  return (
    <TextField
      id={name}
      name={name}
      label={label}
      size="small"
      value={inputValue}
      inputRef={inputRef}
      error={Boolean(error)}
      helperText={error || ''}
      onChange={({ target: { value } }) => handleChange(value)}
      InputLabelProps={{
        shrink: shrink,
      }}
      {...rest}
    />
  );
};

export default memo(Input);
