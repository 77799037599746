import React from 'react';
import { format } from 'date-fns';
import { Container, InfoCurso } from './styles';

const CardInfoCurso = ({ matricula }) => {
  const dataInicio = format(new Date(matricula.dataInicio), 'dd/MM/yyyy');
  const dataTermino = format(new Date(matricula.dataTermino), 'dd/MM/yyyy');

  return (
    <Container>
      <div>
        <strong>{matricula.curso}</strong>
      </div>
      <InfoCurso>
        <div>
          <strong>Matrícula: </strong>
          <span>{matricula.matricula}</span>
        </div>
        <div>
          <strong>Status Matrícula: </strong>
          <span>{matricula.status}</span>
        </div>

        <div>
          <strong>Carga Horária: </strong>
          <span>{`${matricula.cargaHoraria}h`}</span>
        </div>
        <div>
          <strong>Frequência Semanal: </strong>
          <span>{matricula.frequenciaSemanal}</span>
        </div>

        <div>
          <strong>Período: </strong>
          <span>{`${dataInicio} - ${dataTermino}`}</span>
        </div>
        <div>
          <strong>Situação do Curso: </strong>
          <span>{matricula.situacaoTurma}</span>
        </div>
      </InfoCurso>
    </Container>
  );
};

export default CardInfoCurso;
