import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from './styles';
import ChangeCurso from './ChangeCurso';

const CardCurso = () => {
  const { matricula } = useSelector(state => state.auth);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <div>
        <span>As informações abaixo são referente ao curso</span>
        <strong>
          {matricula ? matricula.curso : 'Nenhuma matricula selecionada.'}
        </strong>
      </div>
      <button onClick={handleClickOpen}>Trocar</button>
      <ChangeCurso open={open} onClose={handleClose} />
    </Container>
  );
};

export default CardCurso;
