const environment = {
  api: {
    baseUrl: 'https://portalalunoapi.go.senac.br/api/',
  },
  apiBaseUnicaPessoa: {
    baseUrl: 'https://baseunica.go.senac.br/pessoa/api/',
  },
  apiViaCep: {
    baseUrl: 'https://viacep.com.br/ws/',
  },
  linkAva: {
    url: 'https://ead.senacgoon.com.br'
  },
  linkBo:{
    url: 'https://oportunidades.go.senac.br'
  },
  auth: {
    baseUrlAuth: 'https://login.microsoftonline.com/',
    endPointLogin: 'common/oauth2/authorize',
    endPointLogout: 'common/oauth2/v2.0/logout',
    clientId: '35ed9412-9d1b-4787-a3ed-5967ea261cbd',
    resource: '35ed9412-9d1b-4787-a3ed-5967ea261cbd',
    responseType: 'code',
    redirectUri: 'https://portalaluno.go.senac.br/auth',
    pathName: '/auth',
    routerNotAuth: ['/v1/auth', '/v1/auth/auth/refresh-token'],
    urlManagerUser: 'https://manageruseroffice365api.go.senac.br/api',
  },
  toast: {
    timer: 8000,
  },
};

export default environment;
