import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > header {
    width: 100%;
    margin: 24px 0;
    font-weight: 700;
    font-size: 18px;
    color: #222222;
    line-height: 22px;
  }
`;

export const Services = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
`;
