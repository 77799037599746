import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import LocalidadeService from '../../../services/localidade';
import { useCadastro } from '../../../contexts/Cadastro';

const InputCidade = ({ label, name, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const { uf, cidade, setCidade, cepNotFound } = useCadastro();

  const [shrink, setShrink] = useState(false);
  const [listaCidades, setListaCidades] = useState([]);
  const [value, setValue] = useState(cidade);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    async function getCidades() {
      const response = await LocalidadeService.getCidadeByUf(uf);
      setListaCidades([...response.data]);
    }
    if (uf && cepNotFound) {
      setCidade({ codigoIBGE: '', descricao: '' });
      getCidades();
    }
  }, [uf]);

  useEffect(() => {
    if (cidade) {
      setValue(cidade);
      setInputValue(cidade.descricao);

      if (cidade.descricao === '') {
        setShrink(false);
      } else {
        setShrink(true);
      }
    }
  }, [cidade]);

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        getValue() {
          return value.codigoIBGE;
        },
      });
    }
  }, [fieldName, registerField, value]);

  const handleValue = newValue => {
    setCidade({
      codigoIBGE: newValue.codigoIBGE,
      descricao: newValue.descricao,
    });
  };

  return (
    <Autocomplete
      id={name}
      value={value}
      onChange={(_, newValue) => {
        if (newValue) {
          handleValue(newValue);
        }
      }}
      inputValue={inputValue}
      options={listaCidades}
      autoHighlight
      getOptionSelected={(option, value) =>
        option.codigoIBGE === value.codigoIBGE
      }
      getOptionLabel={option => option.descricao}
      renderOption={option => (
        <React.Fragment>{option.descricao}</React.Fragment>
      )}
      disabled={!cepNotFound}
      {...rest}
      renderInput={params => (
        <TextField
          id={name}
          name={name}
          inputRef={inputRef}
          error={Boolean(error)}
          helperText={error || ''}
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          InputLabelProps={{
            shrink: shrink,
          }}
        />
      )}
    />
  );
};

export default InputCidade;
