import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import { Container, ListItem, Content, Media } from './styles';
import LinkService from './../../services/link';
import { LinkExternal } from 'styled-icons/boxicons-regular';

const Aside = () => {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    async function getLinks() {
      const data = await LinkService.getAllLink();
      setLinks(data);
    }
    getLinks();
  }, []);

  return (
    <Container>
      <div>
        Links importantes
        <List>
          {links.map(l => (
            <ListItem
              button
              onClick={() => window.open(l.url, '_blank')}
              key={l.url}
            >
              <Content>
                <div>
                  <span>{l.titulo}</span>
                </div>
              </Content>
              <Media>
                <LinkExternal />
              </Media>
            </ListItem>
          ))}
        </List>
      </div>
    </Container>
  );
}

export default Aside;
