import React from 'react';
import { useSelector } from 'react-redux';
import logo from '../../assets/img/logo-senac.png';
import { Container, TopBar } from './styles';
import Logout from '../Logout';

const Header = () => {
  const { user } = useSelector(state => state.auth);

  return (
    <Container>
      <TopBar>
        <img src={logo} alt="Senac" />
        <div>
          Oi, {user.fullname}!
          <button>
            {user.fullname.charAt(0).toUpperCase()}
          </button>
          <Logout />
        </div>
      </TopBar>
    </Container>
  );
};

export default Header;
