import styled from 'styled-components';

export const Container = styled.header`
  z-index: 2;
  position: sticky;
  top: 0;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 2px 0px rgba(163, 163, 163, 0.3);
`;

export const TopBar = styled.div`
  width: 100%;
  max-width: 1125px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    font-weight: 400;
    color: #778a9e;
    display: flex;
    align-items: center;

    > button:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      color: #ffffff;
      background: var(--senac-secundary);
      width: 36px;
      height: 36px;
      margin-left: 12px;
      border-radius: 4px;
      border: 0;
      outline: 0;
    }
  }

  @media (max-width: 1024px) {
    max-width: 762px;
    height: 80px;
    padding: 0 24px;

    > img {
      max-width: 80px;
    }
  }
`;
