import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, ListItem, Content } from './styles';
import TrocaCursoService from './../../../services/trocaCurso';
import { actions } from '../../../store/modules/auth';
import IconCurso from '../IconCurso';

const ChangeCurso = ({ onClose, selectedValue, open }) => {
  const dispatch = useDispatch();
  const { cpf } = useSelector(state => state.auth.user);
  const { matricula } = useSelector(state => state.auth);
  const [matriculas, setMatriculas] = useState([]);

  useEffect(() => {
    async function getTurmas() {
      dispatch(actions.setSpinner(true));
      const response = await TrocaCursoService.getTrocaCurso(cpf);
      if (response.status === 200) {
        setMatriculas(response.data.reverse());
        if (!matricula) {
          dispatch(actions.setMatricula(response.data[0]));
        }
      }
      dispatch(actions.setSpinner(false));
    }
    getTurmas();
  }, []);

  const handleListItemClick = value => {
    onClose();
    dispatch(actions.setMatricula(value));
  };

  return (
    <Container aria-labelledby="simple-dialog-title" open={open} onClose={onClose}>
      <DialogTitle id="simple-dialog-title">
        Selecione o curso para gerenciar
      </DialogTitle>
      <List>
        {matriculas.map(m => (
          <ListItem
            button
            onClick={() => handleListItemClick(m)}
            key={m.matricula}
          >
            <IconCurso segmento={m.segmento} />
            <Content>
              <div>
                <strong>Curso:</strong>
                <span>{m.curso}</span>
              </div>
              <div>
                <strong>Matrícula: </strong>
                <span>{m.matricula}</span>
              </div>
              {m.anoSemestre && (
                <>
                  <div>
                    <strong>Ano/Semestre: </strong>
                    <span>{m.anoSemestre}</span>
                  </div>
                </>
              )}
            </Content>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default ChangeCurso;
