export const mapper = data => {
  const object = {
    nome: data.nome,
    escolaridade: data.escolaridade,
    email: data.email,
    telefones: data.telefones,
    endereco: {
      CodigoIBGE: data.endereco.cidade.codigoIBGE,
      logradouro: data.endereco.logradouro,
      complemento: data.endereco.complemento,
      numero: data.endereco.numero,
      cep: data.endereco.cep,
      bairro: data.endereco.bairro,
    },
  };

  return object;
};
