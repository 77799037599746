const sexo = [
  {
    value: 'M',
    label: 'Masculino',
  },
  {
    value: 'F',
    label: 'Feminino',
  },
];

const tipoTelefone = [
  {
    value: 0,
    label: 'Residencial',
  },
  {
    value: 1,
    label: 'Celular',
  },
  {
    value: 2,
    label: 'Comercial',
  }
];

const escolaridade = [
  {
    value: 0,
    label: 'Sem Instrução',
  },
  {
    value: 1,
    label: 'Ensino Fundamental Incompleto',
  },
  {
    value: 2,
    label: 'Ensino Fundamental Completo',
  },
  {
    value: 3,
    label: 'Ensino Médio Incompleto',
  },
  {
    value: 4,
    label: 'Ensino Médio Completo',
  },
  {
    value: 15,
    label: 'Ensino Técnico Incompleto',
  },
  {
    value: 16,
    label: 'Ensino Técnico Completo',
  },
  {
    value: 5,
    label: 'Superior Incompleto',
  },
  {
    value: 6,
    label: 'Superior Completo',
  },
  {
    value: 7,
    label: 'Pós Graduação Incompleto',
  },
  {
    value: 8,
    label: 'Pós Graduação Completo',
  },
  {
    value: 9,
    label: 'Mestrado Incompleto',
  },
  {
    value: 10,
    label: 'Mestrado Completo',
  },
  {
    value: 11,
    label: 'Doutorado Incompleto',
  },
  {
    value: 12,
    label: 'Doutorado Completo',
  },
  {
    value: 13,
    label: 'Pós Doutorado Incompleto',
  },
  {
    value: 14,
    label: 'Pós Doutorado Completo',
  },
];

const estados = [
  {
    value: 'AC',
    label: 'ACRE',
    id: 12,
  },
  {
    value: 'AL',
    label: 'ALAGOAS',
    id: 27,
  },
  {
    value: 'AM',
    label: 'AMAZONAS',
    id: 13,
  },
  {
    value: 'AP',
    label: 'AMAPÁ',
    id: 16,
  },
  {
    value: 'BA',
    label: 'BAHIA',
    id: 29,
  },
  {
    value: 'CE',
    label: 'CEARÁ',
    id: 23,
  },
  {
    value: 'DF',
    label: 'DISTRITO FEDERAL',
    id: 53,
  },
  {
    value: 'ES',
    label: 'ESPÍRITO SANTO',
    id: 32,
  },
  {
    value: 'GO',
    label: 'GOIÁS',
    id: 52,
  },
  {
    value: 'MA',
    label: 'MARANHÃO',
    id: 21,
  },
  {
    value: 'MG',
    label: 'MINAS GERAIS',
    id: 31,
  },
  {
    value: 'MS',
    label: 'MATO GROSSO DO SUL',
    id: 50,
  },
  {
    value: 'MT',
    label: 'MATO GROSSO',
    id: 51,
  },
  {
    value: 'PA',
    label: 'PARÁ',
    id: 15,
  },
  {
    value: 'PB',
    label: 'PARAÍBA',
    id: 25,
  },
  {
    value: 'PE',
    label: 'PERNAMBUCO',
    id: 26,
  },
  {
    value: 'PI',
    label: 'PIAUÍ',
    id: 22,
  },
  {
    value: 'PR',
    label: 'PARANÁ',
    id: 41,
  },
  {
    value: 'RJ',
    label: 'RIO DE JANEIRO',
    id: 33,
  },
  {
    value: 'RN',
    label: 'RIO GRANDE DO NORTE',
    id: 24,
  },
  {
    value: 'RO',
    label: 'RONDÔNIA',
    id: 11,
  },
  {
    value: 'RR',
    label: 'RORAIMA',
    id: 14,
  },
  {
    value: 'RS',
    label: 'RIO GRANDE DO SUL',
    id: 43,
  },
  {
    value: 'SC',
    label: 'SANTA CATARINA',
    id: 42,
  },
  {
    value: 'SE',
    label: 'SERGIPE',
    id: 28,
  },
  {
    value: 'SP',
    label: 'SÃO PAULO',
    id: 35,
  },
  {
    value: 'TO',
    label: 'TOCANTINS',
    id: 17,
  },
];

export { sexo, tipoTelefone, escolaridade, estados };
