import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > header {
    width: 100%;
    margin: 10px 0;
    font-weight: 700;
    font-size: 18px;
    color: #222222;
    line-height: 22px;
  }

  p {
    margin: 10px 0;
    font-weight: 600;
  }
`;

export const Services = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
`;
