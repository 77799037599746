import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  background: #fff;
  border: 1px solid var(--senac-secundary);
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  strong {
    font-size: 16px;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    color: #778a9e;
    line-height: 20px;
    margin-left: 6px;
  }

  > a {
    width: 120px;
    height: 23px;
    text-align: center;
    border-radius: 4px;
    background: #a2acb8;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }
  }
`;
