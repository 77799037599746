import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { actions } from '../../../store/modules/auth';
import { passwordRecoverService } from '../../../services/password-recover';

const PasswordReset = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const paramsBusca = new URLSearchParams(search);
  const email = paramsBusca.get('email');
  const code = paramsBusca.get('code');

  useEffect(() => {
    handle();
  }, []);

  const handle = async () => {
    dispatch(actions.setSpinner(true));

    const { succeeded, message } = await passwordRecoverService.reset({ email, code });

    dispatch(actions.setSpinner(false));

    if (succeeded) {
      toast.success(message);
    } else {
      toast.error(message);
    }

    history.push('/');
  }

  return <></>
}

export default PasswordReset;
