import { viaCep } from './api';

export default class ViaCepService {
  static async getEndereco(cep) {
    const response = await viaCep.get(`${cep}/json`);

    if (response.status === 200) {
      if (response.data.erro) {
        return false;
      }
      return response.data;
    }
    return false;
  }
}
