import axios from 'axios';
import env from '../config/environments';

const api = axios.create({
  baseURL: env.api.baseUrl,
});

export const buPessoa = axios.create({
  baseURL: env.apiBaseUnicaPessoa.baseUrl,
});

export const viaCep = axios.create({
  baseURL: env.apiViaCep.baseUrl,
});

export default api;
