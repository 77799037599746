import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > header {
    width: 100%;
    margin: 24px 0;
    font-weight: 700;
    font-size: 18px;
    color: #222222;
    line-height: 22px;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  padding: 10px 0px 10px 0px;

  > div {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 10px 0px;

    span {
      font-weight: 700;
      font-size: 20px;
      color: #222222;
      line-height: 20px;
    }
  }

  > button {
    width: 89px;
    height: 40px;
    border-radius: 4px;
    background: #eff2f6;
    font-weight: 700;
    font-size: 16px;
    color: #a2acb8;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 490px) {
    flex-direction: column;

    > button {
      width: 100%;
      height: 34px;
      margin-top: 12px;
    }
  }
`;
