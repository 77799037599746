import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { actions } from '../../../store/modules/auth';
import { passwordRecoverService } from '../../../services/password-recover';
import { Form, Button } from '../styles';

const PasswordRequest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef(null);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!inputRef.current.value) {
      return;
    }

    const value = inputRef.current.value;
    const cpf = value.replace(/[^\d]/g, "");

    if (cpf.length !== 11) {
      toast.warning('CPF inválido!');
      return;
    }

    dispatch(actions.setSpinner(true));

    const { succeeded, message } = await passwordRecoverService.request({ cpf });

    dispatch(actions.setSpinner(false));

    if (succeeded) {
      toast.success(message);
      history.push('/');
      return;
    }

    toast.error(message);
  }

  return (
    <>
      <button className="btn-voltar" onClick={() => history.push('/')}>Voltar</button>

      <h2>Digite seu CPF para solicitar uma nova senha: </h2>

      <Form onSubmit={handleSubmit}>
        <input ref={inputRef} id="cpf" name="cpf" placeholder="CPF (apenas números)"/>
        <Button className="secundary">Enviar</Button>
      </Form>
    </>
  );
}

export default PasswordRequest;
