import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  background: #fff;
  border: 1px solid var(--senac-secundary);
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 12px;

  >div:first-child {
    margin-bottom: 10px;
  }

  strong {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export const InfoCurso = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    color: #778a9e;
    line-height: 20px;
    margin-left: 6px;
  }

  strong {
    font-size: 16px;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
  }
`;
