import { buPessoa } from './api';

export default class LocalidadeService {
  static async getUf() {
    return buPessoa.get(`v1/estado`);
  }

  static async getCidadeByUf(uf) {
    return buPessoa.get(`v1/cidade/${uf}`);
  }
}
