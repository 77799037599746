import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

const Title = ({ descricao }) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container>
      <div>
        <span>{descricao}</span>
      </div>
      <button onClick={handleGoBack}>Voltar</button>
    </Container>
  );
};

export default Title;
