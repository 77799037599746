import styled from 'styled-components';

export const Media = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: ${props => props.icon.background};
  margin-right: 4px;

  > svg {
    width: ${props => props.icon.width};
    height: ${props => props.icon.height};
    color: ${props => props.icon.color};
  }
`;
