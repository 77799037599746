import React from 'react';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import CardCurso from '../../components/CardCurso';
import { Container, Content, Main } from './styles';

const Layout = ({ children }) => {
  return (
    <Container>
      <Header />
      <Content>
        <Aside />
        <Main>
          <CardCurso />
          {children}
        </Main>
      </Content>
    </Container>
  );
};

export default Layout;
