import * as Yup from 'yup';

// TODO: Adicionar mensagens amigáveis de validação.
const schema = Yup.object().shape({
  nome: Yup.string()
    .required('Informe o nome.')
    .min(15, 'Informe o nome completo.'),
  cpf: Yup.string().required(),
  email: Yup.string().email().required(),
  sexo: Yup.string().required(),
  nomeMae: Yup.string().required(),
  dataNascimento: Yup.string().required(),
  escolaridade: Yup.string().required(),
  telefones: Yup.array()
    .of(
      Yup.object().shape({
        tipo: Yup.string().required('Informe o tipo do telefone.'),
        numero: Yup.string().required('Informe o telefone para contato.'),
      })
    )
    .min(1, 'Informe pelo menos um telefone para contato.')
    .required(),
  endereco: Yup.object().shape({
    cidade: Yup.object().shape({
      codigoIBGE: Yup.string().required('Informe a cidade.'),
      estado: Yup.object().shape({
        uf: Yup.string().required('Informe a Estado.'),
      }),
    }),
    cep: Yup.string()
      .matches(/(\d{5}-\d{3})/g, 'CEP inválido. Ex.: 12345-678')
      .required('Informe o CEP.'),
    bairro: Yup.string()
      .required('Informe o bairro.')
      .min(3, 'O bairro deve ter no mínimo 3 caracteres.')
      .max(60, 'O bairro deve ter no máximo 80 caracteres.'),
    logradouro: Yup.string()
      .required('Informe o logradouro.')
      .min(3, 'O logradouro deve ter no mínimo 3 caracteres.')
      .max(60, 'O logradouro deve ter no máximo 80 caracteres.'),
    complemento: Yup.string()
      .required('Informe o complemento.')
      .min(3, 'O complemento deve ter no mínimo 3 caracteres.')
      .max(60, 'O complemento deve ter no máximo 80 caracteres.'),
  }),
});

const validate = async data => {
  try {
    await schema.validate(data, {
      abortEarly: false,
    });
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      const validationErrors = {};
      err.inner.forEach(error => {
        validationErrors[error.path] = error.message;
      });
      return validationErrors;
    }
  }
};

export { validate };
