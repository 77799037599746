export const segmentos = [
  {
    key: '1',
    header: 'Gestão',
    icon: {
      name: 'gestao',
      color: '#FFFFFF',
      background: '#845E94',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '2',
    header: 'Comércio',
    icon: {
      name: 'comercio',
      color: '#FFFFFF',
      background: '#DD70BF',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '4',
    header: 'Comunicação',
    icon: {
      name: 'comunicacao',
      color: '#FFFFFF',
      background: '#61CA71',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '5',
    header: 'Artes',
    icon: {
      name: 'artes',
      color: '#FFFFFF',
      background: '#6C5AE2',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '6',
    header: 'Design',
    icon: {
      name: 'design',
      color: '#FFFFFF',
      background: '#EC8A23',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '7',
    header: 'Beleza',
    icon: {
      name: 'beleza',
      color: '#FFFFFF',
      background: '#F2BB13',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '74',
    header: 'Meio Ambiente',
    icon: {
      name: 'meioAmbiente',
      color: '#FFFFFF',
      background: '#9996A7',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '73',
    header: 'Processos Químicos',
    icon: {
      name: 'processosQuimicos',
      color: '#FFFFFF',
      background: '#CD3A52',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '72',
    header: 'Transporte e logística',
    icon: {
      name: 'transporteLogistica',
      color: '#FFFFFF',
      background: '#EE979C',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '71',
    header: 'Construção e reforma',
    icon: {
      name: 'construcaoReforma',
      color: '#FFFFFF',
      background: '#9DD438',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '9',
    header: 'Conservação e zeladoria',
    icon: {
      name: 'conservacaoZeladoria',
      color: '#FFFFFF',
      background: '#E96164',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '68',
    header: 'Gastronomia',
    icon: {
      name: 'gastronomia',
      color: '#FFFFFF',
      background: '#92A9D2',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '27',
    header: 'Social',
    icon: {
      name: 'social',
      color: '#FFFFFF',
      background: '#CE3A6C',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '25',
    header: 'Turismo',
    icon: {
      name: 'turismo',
      color: '#FFFFFF',
      background: '#2C5E84',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '24',
    header: 'Segurança',
    icon: {
      name: 'seguranca',
      color: '#FFFFFF',
      background: '#3F80B2',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '23',
    header: 'Idiomas',
    icon: {
      name: 'idiomas',
      color: '#FFFFFF',
      background: '#EA766B',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '22',
    header: 'Moda',
    icon: {
      name: 'moda',
      color: '#FFFFFF',
      background: '#E96585',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '21',
    header: 'Lazer',
    icon: {
      name: 'lazer',
      color: '#FFFFFF',
      background: '#30686B',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '20',
    header: 'Telecomunicações',
    icon: {
      name: 'telecomunicacoes',
      color: '#FFFFFF',
      background: '#D3D363',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '19',
    header: 'Educacional',
    icon: {
      name: 'educacional',
      color: '#FFFFFF',
      background: '#5AB3E7',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '11',
    header: 'Saúde',
    icon: {
      name: 'saude',
      color: '#FFFFFF',
      background: '#40859D',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '14',
    header: 'Informática',
    icon: {
      name: 'informatica',
      color: '#FFFFFF',
      background: '#B3906A',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '75',
    header: 'Games',
    icon: {
      name: 'games',
      color: '#FFFFFF',
      background: '#6A88B3',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '66',
    header: 'Eventos',
    icon: {
      name: 'eventos',
      color: '#FFFFFF',
      background: '#B36BA1',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '67',
    header: 'Hospedagem',
    icon: {
      name: 'hospedagem',
      color: '#FFFFFF',
      background: '#B26F6A',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '29',
    header: 'Produção de alimentos',
    icon: {
      name: 'roducaoAlimentos',
      color: '#FFFFFF',
      background: '#91B26B',
      width: '22px',
      height: '22px',
    },
  },
  {
    key: '28',
    header: 'Produção de bebidas',
    icon: {
      name: 'producaoBebidas',
      color: '#FFFFFF',
      background: '#6AB2AB',
      width: '22px',
      height: '22px',
    },
  }
];
