import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import { Container, ListItem, Content, Media } from './styles';
import JasperService from '../../services/jasper';
import HistoricoEscolarService from '../../services/historicoEscolar';
import Title from '../../components/Title';
import { Report } from 'styled-icons/boxicons-solid';
import { actions } from '../../store/modules/auth';
import { toast } from "react-toastify";

const Academico = () => {
  const dispatch = useDispatch();
  const { matricula } = useSelector(state => state.auth);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    async function getReports() {
      const data = await JasperService.getAllReports();
      data.push({
        label: 'Histórico Escolar',
        uri: false,
        extension: '.pdf'
      });
      setReports(data);
    }
    getReports();
  }, []);

  const downloadReport = async report => {
    if (matricula) {
      let response = {};
      dispatch(actions.setSpinner(true));
      if (report.uri) {
        response = await JasperService.getReport(matricula.matricula, report.uri, report.extension);
      }else {
        response = await HistoricoEscolarService.getReport(matricula.matricula);
      }

      if (response.status === 200) {
        const downloadLink = document.createElement("a");
        downloadLink.href = `data:application/pdf;base64,${response.data.arquivo}`;
        downloadLink.download = report.label + report.extension;
        downloadLink.click();
      }else {
        toast.warning('Não foi possível fazer o download do relatório.');
      }

      dispatch(actions.setSpinner(false));
      return;
    }

    toast.warning('Selecione uma matrícula para gerar o relatório.');
  };

  return (
    <Container>
      <Title descricao="Relatórios Acadêmicos"/>
        <List>
          {reports.map(r => (
            <ListItem
              button
              onClick={() => downloadReport(r)}
              key={r.uri}
            >
              <Media>
                <Report />
              </Media>
              <Content>
                <div>
                  <span>{r.label}</span>
                </div>
              </Content>
            </ListItem>
          ))}
        </List>
    </Container>
  );
}

export default Academico;
