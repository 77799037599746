import api from 'axios';
import env from '../config/environments';

const handleResponse = () => {
  api.interceptors.response.use(
    async function (response) {
      return response;
    },
    async function (error) {
      return error;
    }
  );
};

const passwordRecoverService = {

  request: async ({ cpf }) => {
    handleResponse();
    const response = await api.post(`${env.auth.urlManagerUser}/v1/password-recover/request`, {
      cpf,
      urlCallback: "https://portalaluno.go.senac.br/password-reset"
    });

    if (response && response.status === 200) {
      const email = response.data;

      return {
        succeeded: true,
        message: `Um link para recuperar a senha será enviado para seu e-mail ${email}.`
      }
    }

    return {
      succeeded: false,
      message: "Houve um problema ao tentar recuperar sua senha. Tente novamente em alguns minutos. Verifique se digitou o CPF correto."
    }
  },

  reset: async ({ email, code }) => {
    handleResponse();
    const response = await api.patch(`${env.auth.urlManagerUser}/v1/password-recover/reset`, {
      emailPessoal: email,
      token: code
    });

    if (response && response.status === 204) {
      return {
        succeeded: true,
        message: `Você irá receber em seu e-mail uma senha de acesso temporária.`
      }
    }

    return {
      succeeded: false,
      message: "Houve um problema ao tentar recuperar sua senha."
    }
  }
}

export {
  passwordRecoverService
}
