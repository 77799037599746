import React from 'react';
import { AuthProvider } from './contexts/Auth';
import Routes from './router';
import GlobalStyle from './styles/Global';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { LastLocationProvider } from 'react-router-last-location';
import Spinner from './components/Shared/Spinner';

function App({ store, persistor, basename }) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AuthProvider>
          <BrowserRouter basename={basename}>
            <Spinner>
              <LastLocationProvider>
                <Routes />
                <GlobalStyle />
              </LastLocationProvider>
            </Spinner>
          </BrowserRouter>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
