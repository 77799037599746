import { Laptop } from 'styled-icons/entypo';
import { MortarBoard } from 'styled-icons/octicons';
import { DocumentText } from 'styled-icons/ionicons-solid';
import { MoneyDollarCircle } from 'styled-icons/remix-fill';
import { Book } from 'styled-icons/boxicons-solid';
import { ShoppingBag } from 'styled-icons/foundation';
import { ReactComponent as Artes } from '../assets/icones/artes.svg';
import { ReactComponent as Beleza } from '../assets/icones/beleza.svg';
import { ReactComponent as Comercio } from '../assets/icones/comercio.svg';
import { ReactComponent as Comunicacao } from '../assets/icones/comunicacao.svg';
import { ReactComponent as ConservacaoZeladoria } from '../assets/icones/conservacao-e-zeladoria.svg';
import { ReactComponent as ConstrucaoReforma } from '../assets/icones/construcao-reforma.svg';
import { ReactComponent as Design } from '../assets/icones/design.svg';
import { ReactComponent as Educacional } from '../assets/icones/educacional.svg';
import { ReactComponent as Eventos } from '../assets/icones/eventos.svg';
import { ReactComponent as Games } from '../assets/icones/games.svg';
import { ReactComponent as Gastronomia } from '../assets/icones/gastronomia.svg';
import { ReactComponent as Gestao } from '../assets/icones/gestao.svg';
import { ReactComponent as Hospedagem } from '../assets/icones/hospedagem.svg';
import { ReactComponent as Idiomas } from '../assets/icones/idiomas.svg';
import { ReactComponent as Informatica } from '../assets/icones/informatica.svg';
import { ReactComponent as Lazer } from '../assets/icones/lazer.svg';
import { ReactComponent as MeioAmbiente } from '../assets/icones/meio-ambiente.svg';
import { ReactComponent as Moda } from '../assets/icones/moda.svg';
import { ReactComponent as ProcessosQuimicos } from '../assets/icones/processos-quimicos.svg';
import { ReactComponent as ProducaoAlimentos } from '../assets/icones/producao-de-alimentos.svg';
import { ReactComponent as ProducaoBebidas } from '../assets/icones/producao-de-bebidas.svg';
import { ReactComponent as Saude } from '../assets/icones/saude.svg';
import { ReactComponent as Seguranca } from '../assets/icones/seguranca.svg';
import { ReactComponent as Social } from '../assets/icones/social.svg';
import { ReactComponent as Telecomunicacoers } from '../assets/icones/telecomunicacoes.svg';
import { ReactComponent as TransporteLogistica } from '../assets/icones/transporte-e-logistica.svg';
import { ReactComponent as Turismo } from '../assets/icones/turismo.svg';

export const icons = {
  'laptop': Laptop,
  'capelo': MortarBoard,
  'document': DocumentText,
  'money': MoneyDollarCircle,
  'book': Book,
  'bag': ShoppingBag,
  'artes': Artes,
  'beleza': Beleza,
  'comercio': Comercio,
  'comunicacao': Comunicacao,
  'conservacaoZeladoria': ConservacaoZeladoria,
  'construcaoReforma': ConstrucaoReforma,
  'design': Design,
  'educacional': Educacional,
  'eventos': Eventos,
  'games': Games,
  'gastronomia': Gastronomia,
  'gestao': Gestao,
  'hospedagem': Hospedagem,
  'idiomas': Idiomas,
  'informatica': Informatica,
  'lazer': Lazer,
  'meioAmbiente': MeioAmbiente,
  'moda': Moda,
  'processosQuimicos': ProcessosQuimicos,
  'producaoAlimentos': ProducaoAlimentos,
  'producaoBebidas': ProducaoBebidas,
  'saude': Saude,
  'seguranca': Seguranca,
  'social': Social,
  'telecomunicacoers': Telecomunicacoers,
  'transporteLogistica': TransporteLogistica,
  'turismo': Turismo
};
