import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from './styles';

import logo from '../../assets/img/logo-senac.png';

import AuthPage from './AuthPage';
import Login from './Login';
import PasswordRequest from './PasswordRequest';
import PasswordReset from './PasswordReset';

const Auth = () => {

  return (
    <Container>

      <img src={logo} alt="Senac Goiás"/>

      <Switch>
        <Route exact path="/" component={AuthPage} />
        <Route path="/auth" component={Login} />
        <Route path="/password-request" component={PasswordRequest} />
        <Route path="/password-reset" component={PasswordReset} />
      </Switch>
    </Container>
  );
};

export default Auth;
