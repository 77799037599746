import api from './api';

export default class JasperService {
  static async getAllReports() {
    const response = await api.get('v1/jasper');
    return response.data;
  }

  static async getReport(matricula, uri, extensao) {
    const response = await api.get(`v1/jasper/imprimir?matricula=${matricula.replace(/\D/g, '')}&url=${uri}&extensao=${extensao}`);
    return response;
  }
}
