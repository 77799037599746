import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 40px;
  }

  .primary {
    background-color: #f7941d;
  }

  .secundary {
    background-color: #6c757d;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
  }

  .btn-voltar {
    position: fixed;
    top: 16px;
    left: 16px;

    font-size: 16px;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }
`;

export const Button = styled.button`
  width: 80%;
  max-width: 260px;
  height: 50px;
  padding: 7px 17px;
  color: #fff;
  border: none;
  border-radius: 4px;

  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 37px;

  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const Form = styled.form`
  width: 80%;
  max-width: 300px;
  display: flex;
  align-items: center;

  margin-top: 18px;

  input {
    width: 200px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 7px 17px;
    color: #555;

    margin-right: 16px;
    font-size: 14px;
  }

  button {
    margin-bottom: unset;
  }

  @media (max-width: 380px) {
    flex-direction: column;

    input {
      width: 90%;
      margin-right: unset;
    }

    button {
      width: 90%;
      margin-top: 16px;
    }
  }
`;
